<template>
  <div class="ad-new" v-loading.fullscreen.lock="loading">
    <el-row>
      <ai-breadcrumb />
    </el-row>
    <el-form class="form" ref="form" :model="form" size="small" label-width="180px">
      <el-form-item :label="$t('gpt.desc')">
        <el-input v-model="form.desc" size="small" style="width: 30%"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item class="source-img-form-item" :label="$t('gpt.modelImage')" required>
            <el-upload :class="sourceFileList && sourceFileList.length > 0 ? 'hide-upload' : ''"
              :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png" :headers="{ Authorization: this.token }"
              list-type="picture-card" :file-list="sourceFileList" :on-change="handleChangeSource"
              :on-remove="handleRemoveSource" :data="{ group: 'gpt' }" :multiple="false" :limit="1">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="source-img-form-item" label="Manual Mask">
            <el-button icon="el-icon-edit" @click="showMaskEditor = true">Edit</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <img v-if="maskImageUrl" :src="maskImageUrl"
            style="width: 124px; height: 124px; object-fit: contain; border: 1px dashed #d9d9d9; border-radius: 6px;">
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item class="source-img-form-item" :label="$t('gpt.productImage')" required>
            <el-upload :class="targetFileList && targetFileList.length > 0 ? 'hide-upload' : ''"
              :action="aiApiRoot + '/v1/images'" accept=".jpg, .jpeg, .png" :headers="{ Authorization: this.token }"
              list-type="picture-card" :file-list="targetFileList" :on-change="handleChangeTarget"
              :on-remove="handleRemoveTarget" :data="{ group: 'gpt' }" :multiple="false" :limit="1">
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <div class="notice">
            <i18n path="gpt.newInfoAD" tag="div">
            </i18n>
          </div>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button type="primary" icon="el-icon-s-promotion" @click="onSubmit">{{ $t("common.submit") }}</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="Edit Mask" :visible.sync="showMaskEditor" custom-class="mask-dialog">
      <mask-editor ref="maskEditor"
        :image-url="sourceFileList && sourceFileList.length > 0 ? sourceFileList[0].url : ''" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMaskEditor = false" size="small">{{
          $t("common.cancel")
          }}</el-button>
        <el-button type="primary" @click="handleMaskConfirm" size="small">{{
          $t("common.ok")
          }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createTask } from "@/api/gpt";
import { uploadImage } from "@/api/image";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import MaskEditor from "@/components/MaskEditor";
import { getToken } from "@/utils/auth";
import { aiApiRoot } from "@/utils/server";
import { imageUrl } from "@/filters";

export default {
  components: {
    AiBreadcrumb,
    MaskEditor,
  },
  data() {
    return {
      token: "Bearer " + getToken(),
      aiApiRoot,
      total: 0,
      form: {
        desc: "",
        sourceIds: [],
        targetIds: [],
      },
      showMaskEditor: false,
      sourceFileList: [],
      targetFileList: [],
      loading: false,
      maskImageUrl: null,
      maskEdited: false
    };
  },
  methods: {
    initForm() {
      this.form = {
        desc: "",
        sourceIds: [],
        targetIds: [],
      }
    },
    async saveMask() {
      const response = await fetch(this.maskImageUrl);
      const blob = await response.blob(); // 将数据 URL 转换为 Blob
      const formData = new FormData();
      formData.append('image', blob, 'mask.png'); // 将 Blob 添加到 FormData
      formData.append('group', 'gpt');
      return uploadImage(formData);
    },
    handleMaskConfirm() {
      this.maskEdited = true;
      this.showMaskEditor = false;
      this.maskImageUrl = this.$refs.maskEditor.getMaskData();
    },
    onSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.sourceFileList.length === 0) {
            this.$message({
              message: "Please upload model image",
              type: "warning",
            });
            return;
          }
          if (this.targetFileList.length === 0) {
            this.$message({
              message: "Please upload product image",
              type: "warning",
            });
            return;
          }
          let params = {
            ...this.form, category: "ad", sourceIds: [], targetIds: []
          }
          let finish = true;
          this.sourceFileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.sourceIds.push(t.response.result[0].id);
            }
          });
          this.targetFileList.forEach((t) => {
            if (t.status !== "success") {
              finish = false;
              return;
            }
            if (
              t.response &&
              t.response.result &&
              t.response.result.length > 0
            ) {
              params.targetIds.push(t.response.result[0].id);
            }
          });
          if (!finish) {
            this.$message({
              message: "Image upload not finish, can't submit",
              type: "warning",
            });
            return;
          }
          this.$confirm(
            this.$t("tagger.submitTask"),
            this.$t("common.pleaseConfirm"),
            {
              confirmButtonText: "OK",
              cancelButtonText: "Cancel",
              type: "warning",
            }
          ).then(() => {
            this.loading = true;
            // add mask id to sourceIds
            if (this.maskEdited) {
              this.saveMask().then((fileResp) => {
                if (!fileResp.result || fileResp.result.length === 0 || !fileResp.result[0].id) {
                  this.$message({
                    message: "Invalid image id for mask, can't submit",
                    type: "warning",
                  });
                }
                let imageId = fileResp.result[0].id;
                params.sourceIds.push(imageId);
                createTask(params).then((response) => {
                  this.loading = false;
                  if (response.success) {
                    this.$notify({
                      title: this.$t("common.saveSucc"),
                      message: this.$t("common.indirecting"),
                      type: "success",
                    });
                    let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                    this.$router.push("/services/gpt-ad/ad" + suffix).catch(() => { });
                  }
                })
                  .catch(() => {
                    this.loading = false;
                  });
              });
              return
            }
            createTask(params).then((response) => {
              this.loading = false;
              if (response.success) {
                this.$notify({
                  title: this.$t("common.saveSucc"),
                  message: this.$t("common.indirecting"),
                  type: "success",
                });
                let suffix = response.result && response.result.id ? "/" + response.result.id : ""
                this.$router.push("/services/gpt-ad/ad" + suffix).catch(() => { });
              }
            })
              .catch(() => {
                this.loading = false;
              });
          })
        }
      });
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    handleChangeSource(file, fileList) {
      this.sourceFileList = fileList;
    },
    handleRemoveSource(file, fileList) {
      this.sourceFileList = fileList;
    },
    handleChangeTarget(file, fileList) {
      this.targetFileList = fileList;
    },
    handleRemoveTarget(file, fileList) {
      this.targetFileList = fileList;
    },
  },
  mounted() {
    this.initForm();
  }
};
</script>

<style lang="scss" scoped>
.ad-new {
  .form {
    margin-top: 25px;
  }

  /deep/ .el-upload--picture-card {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 124px;
    height: 124px;
    line-height: 128px;
  }

  /deep/ .el-upload-list__item-status-label i {
    position: absolute;
    right: 15px
  }

  .target-container {
    margin-top: 15px;

    /deep/.el-card__header {
      padding: 5px 15px;
    }

    /deep/.el-card__body {
      padding: 5px;
    }

    .target-block {
      text-align: center;

      .target-img {
        max-width: 80px;
      }
    }

    .pagination-container {
      margin: 0;
      padding-top: 0;
      padding-bottom: 5px;
    }
  }

  /deep/ .mask-dialog .el-form {
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .hide-upload /deep/ .el-upload.el-upload--picture-card {
    display: none;
  }

  /deep/ .el-dialog__footer {
    border-top: 1px solid #ebeef5;
  }

  .notice {
    font-size: small;
    color: #606266;
    margin-left: 40px;
    font-weight: bold;
    white-space: pre-line;
    line-height: 150%;

    .brand {
      color: orange;
    }
  }
}
</style>