<template>
  <div class="mask-editor">
    <div class="editor-container">
      <div class="image-wrapper">
        <img :src="imageUrl" ref="image" @load="initCanvas" class="source-image">
        <canvas ref="canvas" @mousedown="startDrawing" @mousemove="draw" @mouseup="stopDrawing"
          @mouseleave="stopDrawing" :style="canvasCursor"></canvas>
      </div>
      <div class="toolbar">
        <el-row>
          <el-tooltip effect="light" content="Reset" placement="right" :hide-after="1500" :disabled="isMobile">
            <el-button icon="el-icon-refresh" @click="clearCanvas"></el-button>
          </el-tooltip>
        </el-row>
        <el-row>
          <el-button v-if="isMobile" icon="el-icon-refresh-left" @click="undo" />
          <el-tooltip v-else effect="light" content="Undo" placement="right" :hide-after="1500" :disabled="isMobile">
            <el-button icon="el-icon-refresh-left" @click="undo"></el-button>
          </el-tooltip>
        </el-row>
        <el-row>
          <el-tooltip effect="light" content="Clear" placement="right" :hide-after="1500" :disabled="isMobile">
            <el-button v-if="isErasing" icon="el-icon-edit" @click="toggleEraser"></el-button>
            <el-button v-else @click="toggleEraser">
              <svg-icon icon-class="eraser-btn" />
            </el-button>
          </el-tooltip>
        </el-row>
        <el-row>
          <el-tooltip effect="light" content="Use brush" placement="right" :hide-after="1500" :disabled="isMobile">
            <el-popover placement="right" width="200" trigger="click" popper-class="wrap-popover">
              <el-button slot="reference" icon="el-icon-brush"></el-button>
              <el-slider v-model="brushSize" :step="1" :min="10" :max="60" :show-tooltip="!isMobile" show-stops>
              </el-slider>
            </el-popover>
          </el-tooltip>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: String
  },
  data() {
    return {
      isMobile: false,
      isDrawing: false,
      isErasing: false,
      ctx: null,
      brushSize: 20,
      imageDimensions: { width: 0, height: 0 },
      scaleFactor: 1,
      history: [],
      displaySize: { width: 0, height: 0 },
      lastX: 0,
      lastY: 0,
    };
  },
  computed: {
    canvasStyle() {
      return {
        width: `${this.imageDimensions.width}px`,
        height: `${this.imageDimensions.height}px`
      };
    },
    imageStyle() {
      return {
        width: `${this.imageDimensions.width}px`,
        height: `${this.imageDimensions.height}px`
      };
    },
    canvasCursor() {
      const createCursor = (size, isEraser) => {
        const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" 
           width="${size}" 
           height="${size}" 
           viewBox="0 0 ${size} ${size}">
        ${isEraser ?
            `<rect x="0" y="0" width="${size}" height="${size}" fill="#000"/>` :
            `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="#000"/>`
          }
      </svg>`;
        return `url('data:image/svg+xml;utf8,${encodeURIComponent(svg)}') ${size / 2} ${size / 2}, auto`;
      };

      return {
        cursor: createCursor(this.brushSize, this.isErasing)
      }
    }
  },
  mounted() {
    this.checkDevice();
    this.resizeObserver = new ResizeObserver(entries => {
      if (entries[0].contentRect.width > 0) {
        this.initCanvas()
      }
    })
    this.$nextTick(() => {
      this.resizeObserver.observe(this.$el.querySelector('.image-wrapper'))
    })
  },
  beforeDestroy() {
    this.resizeObserver.disconnect()
  },
  methods: {
    checkDevice() {
      this.isMobile = 'ontouchstart' in document.documentElement;
      console.log("check device result: isMobile ==", this.isMobile);
    },
    initCanvas() {
      this.$nextTick(() => {
        const img = this.$refs.image;
        const wrapper = this.$el.querySelector('.image-wrapper');
        if (!wrapper || wrapper.clientWidth === 0) {
          setTimeout(() => this.initCanvas(), 50) // 延迟重试
          return
        }

        // 确保canvas元素存在
        const canvas = this.$refs.canvas;
        if (!canvas) return;

        // 初始化2D上下文
        this.ctx = canvas.getContext('2d');
        if (!this.ctx) {
          console.error('Failed to get 2D context');
          return;
        }

        // 计算显示尺寸
        const displayWidth = wrapper.clientWidth;
        this.scaleFactor = displayWidth / img.naturalWidth;

        // 设置canvas尺寸
        canvas.style.width = `${displayWidth}px`;
        canvas.style.height = `${img.naturalHeight * this.scaleFactor}px`;
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        // 初始化画布状态
        this.ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);
        this.ctx.lineCap = 'round';
        this.ctx.strokeStyle = '#000';
      })
    },

    startDrawing(e) {
      if (!this.ctx) return; // 添加安全判断
      this.isDrawing = true;

      const { x, y } = this.getCanvasCoordinates(e);
      this.lastX = x;
      this.lastY = y;

      this.saveState();
    },

    getCanvasCoordinates(e) {
      const canvas = this.$refs.canvas;
      const rect = canvas.getBoundingClientRect();

      // 计算原始坐标
      let clientX, clientY;
      if (e.type.startsWith('touch')) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }

      // 转换为canvas元素坐标系
      let x = clientX - rect.left;
      let y = clientY - rect.top;

      // 转换为实际canvas尺寸坐标系
      const scaleX = canvas.width / canvas.clientWidth;
      const scaleY = canvas.height / canvas.clientHeight;
      x *= scaleX;
      y *= scaleY;

      // 边界约束
      x = Math.max(0, Math.min(x, canvas.width));
      y = Math.max(0, Math.min(y, canvas.height));

      return { x, y };
    },

    draw(e) {
      if (!this.isDrawing || !this.ctx) return;

      const { x, y } = this.getCanvasCoordinates(e);

      if (this.isErasing) {
        const eraseSize = this.brushSize / this.scaleFactor;
        this.ctx.clearRect(
          x - eraseSize / 2,
          y - eraseSize / 2,
          eraseSize,
          eraseSize
        );
      } else {
        this.ctx.lineWidth = this.brushSize / this.scaleFactor;
        this.ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
        this.ctx.lineCap = 'round';

        this.ctx.beginPath();
        this.ctx.moveTo(this.lastX, this.lastY);
        this.ctx.lineTo(x, y);
        this.ctx.stroke();

        this.lastX = x;
        this.lastY = y;
      }
    },

    stopDrawing() {
      this.isDrawing = false;
      this.ctx.beginPath();
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      this.history = [];
    },
    undo() {
      if (this.history.length > 1) {
        this.history.pop();
        const img = new Image();
        img.src = this.history[this.history.length - 1];
        img.onload = () => {
          this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
          this.ctx.drawImage(img, 0, 0);
        };
      }
    },
    toggleEraser() {
      this.isErasing = !this.isErasing;
    },
    saveState() {
      this.history.push(this.$refs.canvas.toDataURL());
    },
    getMaskData() {
      return this.$refs.canvas.toDataURL('image/png');
    },
    resetCanvas() {
      const canvas = this.$refs.canvas
      if (canvas) {
        canvas.width = 0
        canvas.height = 0
      }
      this.imageDimensions = { width: 0, height: 0 }
    }
  },
  watch: {
    imageUrl(newVal) {
      const img = this.$refs.image
      img.src = ''
      setTimeout(() => img.src = newVal, 0) // 清空缓存强制触发load
    }
  }
};
</script>

<style lang="scss" scoped>
.mask-editor {
  .editor-container {
    margin: 4px 0;
    display: flex;
    width: 100%;
    height: auto;
  }

  .image-wrapper {
    display: inline-flex;
    vertical-align: top;
    line-height: 0;
    margin-right: 10px;
    flex: 1;
    position: relative;
    background: #f0f2f5;
    width: calc(100% - 80px);

    .source-image {
      width: 100%;
      height: auto !important;
      object-fit: contain;
      position: relative;
      z-index: 1;
      pointer-events: none;
    }

    canvas {
      position: absolute !important;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100% !important;
      height: auto !important;
      touch-action: none;
      image-rendering: crisp-edges;
      opacity: 0.7;
      mix-blend-mode: multiply;
    }
  }

  .toolbar {
    flex-shrink: 0;
    width: 40px;
    padding: 0 10px;
    border-left: 1px solid #ebeef5;

    .el-row {
      margin-bottom: 5px;

      .el-button {
        padding: 5px;
        font-size: 18px;
      }
    }
  }
}
</style>